import { projects } from '@/pages/projects-data'
import { getRoutesStandard } from '@/router-standard'
import type { PostMeta } from '@/_types'
import _postsData from './_posts/meta.json'
import { siteConfig } from './config'

const postsData = _postsData as unknown as PostMeta[]

function collectTags() {
  const tags: Record<string, Record<string, string>> = {}
  for (const p of projects) {
    (p.tags ?? []).forEach((t) => {
      let o = tags[t]
      if (o == null)
        o = {}
      o[`/en/projects#${p.name}`] = p.title
      o[`/de/projects#${p.name}`] = p.title
      tags[t] = o
    })
  }

  for (const p of postsData.filter(p => !(p.draft || p.archive))) {
    (p.tags ?? []).forEach((t) => {
      let o = tags[t]
      if (o == null)
        o = {}
      o[`/${p.lang ?? 'en'}/${siteConfig.postsPath}/${p.slug}`] = p.title
      tags[t] = o
    })
  }
  return tags
}

const tagsInfo = collectTags()

export const routes = getRoutesStandard({
  siteConfig,
  pagesImport: import.meta.glob('./_pages/*.md', { eager: false }),
  postsImport: import.meta.glob('./_posts/*.md', { eager: false }),
  postsData,
})

for (const lang of siteConfig.languages) {
  routes.push({
    path: `/${lang}/projects`,
    component: () => import('@/pages/projects.vue'),
  }, {
    path: `/${lang}/support`,
    component: () => import('@/pages/support.vue'),
  }, {
    path: `/${lang}/${siteConfig.postsPath}`,
    meta: { postsData },
    component: () => import('@/pages/posts.vue'),
  }, {
    path: `/${lang}/tags`,
    component: () => import('@/pages/tags.vue'),
    meta: {
      tags: tagsInfo,
    },
  }, ...Object.entries(tagsInfo).map(([tag, posts]) => ({
    path: `/${lang}/tag/${tag}`,
    meta: {
      posts,
      tag,
    },
    component: () => import(`@/components/app-tag.vue`),
  })))
}
